import React, { useState } from 'react';
import { ReferralsContainer, ReferralsRow, ReferralsCount, InviteButton, LinkContainer, CopyText, CopyIcon, Message, InfoButton } from '../styles/Referrals.styles';
import InviteImage from '../assets/Invite.png';
import CopyIconImage from '../assets/copy.png';
import ReferralInfo from './ReferralInfo';

const BOT_URL = 'https://t.me/PoombSpaceBot?start=';
const REF_URL = 't.me/PoombSpaceBot?start=';

const Referrals = ({ referrals, copySuccess, copyToClipboard, username }) => {
  const [showInfo, setShowInfo] = useState(false);

  const handleCopy = () => {
    const referralLink = `${BOT_URL}${username}`;
    copyToClipboard(referralLink);
    navigator.clipboard.writeText(referralLink).then(() => {
      alert('Link copied!');
    });
  };

  const handleShare = () => {
    const referralLink = `${BOT_URL}${username}`;
    if (navigator.share) {
      navigator.share({
        title: 'Join CapyGame',
        text: `Join CapyGame and start earning rewards! Use my referral link: ${referralLink}`,
        url: referralLink,
      }).catch((error) => console.log('Error sharing', error));
    } else {
      alert('Share feature is not supported in your browser');
    }
  };

  const handleInfoClick = (e) => {
    e.stopPropagation();
    setShowInfo(true);
  };

  const handleCloseInfo = () => {
    setShowInfo(false);
  };

  return (
    <ReferralsContainer>
      <ReferralsRow>
        <ReferralsCount>{referrals}</ReferralsCount>
        <InviteButton onClick={handleShare}>
          <img src={InviteImage} alt="Invite Friends" />
          <InfoButton onClick={handleInfoClick} />
        </InviteButton>
      </ReferralsRow>
      <LinkContainer>
        <CopyText onClick={handleCopy}>
          <span>LINK: </span>{REF_URL}{username}
        </CopyText>
        <CopyIcon src={CopyIconImage} alt="Copy Link" onClick={handleCopy} />
      </LinkContainer>
      {showInfo && <ReferralInfo onClose={handleCloseInfo} />}
    </ReferralsContainer>
  );
};

export default Referrals;
