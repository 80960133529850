import React, { useEffect, useState } from 'react';
import characters from '../characters';
import { CharacterContainer, CharacterGrid, CharacterRow, CharacterImage, SelectButton, InfoButton, Title, ButtonContainer } from '../styles/CharacterSelection.styles';
import InformationSection from './InformationSection';

const CharacterSelection = ({ username, onCharacterSelected }) => {
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const [showInfoSection, setShowInfoSection] = useState(false);

  const handleCharacterClick = (characterId) => {
    setSelectedCharacter(characterId);
  };

  const handleCharacterSelect = async () => {
    if (selectedCharacter !== null) {
      await fetch(`${process.env.REACT_APP_API_URL}/select_character`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'ngrok-skip-browser-warning': 'true'
        },
        body: JSON.stringify({ username, character_id: selectedCharacter })
      });
      onCharacterSelected(selectedCharacter);
    }
  };

  const handleShowInfo = () => {
    setShowInfoSection(true);
  };

  const handleCloseInfo = () => {
    setShowInfoSection(false);
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const renderCharacterRows = () => {
    const rows = [];
    characters.forEach((character, index) => {
      if (index === 0) {
        rows.push(
          <CharacterRow key={index}>
            <CharacterImage
              key={character.id}
              src={character.src}
              alt={character.name}
              selected={character.id === selectedCharacter}
              onClick={() => handleCharacterClick(character.id)}
              style={{ margin: '0 auto' }}
            />
          </CharacterRow>
        );
      } else if (index % 2 === 1) {
        rows.push(
          <CharacterRow key={index}>
            <CharacterImage
              key={character.id}
              src={character.src}
              alt={character.name}
              selected={character.id === selectedCharacter}
              onClick={() => handleCharacterClick(character.id)}
            />
            {characters[index + 1] && (
              <CharacterImage
                key={characters[index + 1].id}
                src={characters[index + 1].src}
                alt={characters[index + 1].name}
                selected={characters[index + 1].id === selectedCharacter}
                onClick={() => handleCharacterClick(characters[index + 1].id)}
              />
            )}
          </CharacterRow>
        );
      }
    });
    return rows;
  };

  return (
    <CharacterContainer>
      <InfoButton onClick={handleShowInfo}></InfoButton>
      <Title>
        <h2>Select Your Character</h2>
      </Title>
      <CharacterGrid>
        {renderCharacterRows()}
      </CharacterGrid>
      <ButtonContainer>
        <SelectButton disabled={selectedCharacter === null} onClick={handleCharacterSelect}>
          Select Character
        </SelectButton>
      </ButtonContainer>
      {showInfoSection && (
        <InformationSection
          text="Choose your POOMBY to start an epic adventure!
                Choose carefully and begin the leveling journey!"
          buttonText="Close"
          onClose={handleCloseInfo}
        />
      )}
    </CharacterContainer>
  );
};

export default CharacterSelection;
