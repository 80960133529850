import React, {useEffect} from 'react';
import { LoaderContainer, Spinner, LoadingText } from '../styles/Loader.styles';
import SocialLinks from './SocialLinks';

const Loader = () => {

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <LoaderContainer>
      <Spinner />
      <LoadingText>Loading</LoadingText>
      <SocialLinks bottom="100px" />
    </LoaderContainer>
  );
};

export default Loader;
