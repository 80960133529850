import React, { useState } from 'react';
import { LevelContainer, LevelText, LabelText, InfoButton } from '../styles/LevelDisplay.styles';
import LevelInfo from './LevelInfo';

const LevelDisplay = ({ level }) => {
  const [showInfo, setShowInfo] = useState(false);

  const handleInfoClick = () => {
    setShowInfo(true);
  };

  const handleCloseInfo = () => {
    setShowInfo(false);
  };

  return (
    <LevelContainer>
      <LevelText>{level} <InfoButton onClick={handleInfoClick} /></LevelText>
      <LabelText>LEVEL</LabelText>
      
      {showInfo && (
        <LevelInfo level={level} onClose={handleCloseInfo} />
      )}
    </LevelContainer>
  );
};

export default LevelDisplay;
