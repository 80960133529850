import styled from 'styled-components';
import shopBackground from '../assets/Shop.png'; 
import infoButton from '../assets/infoButton.png'; 

export const CharacterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  text-align: center;
  padding: 20px;
  font-family: 'Marvin', serif;
  background-image: url(${shopBackground});
  background-size: cover;
  background-position: center;
  overflow: hidden;
  position: relative;
`;

export const Title = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  margin-top: 35px;
  align-items: center;
  justify-content: center;
  h2 {
    color: #F8CE00;
    text-shadow: 
      -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000;
    padding: 25px 0 5px 0;
  }
`;

export const CharacterGrid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
`;

export const CharacterRow = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 100%;
`;

export const CharacterImage = styled.img`
  height: 140px;
  border: 2px solid transparent;
  border-radius: 8px;
  cursor: pointer;
  transition: border-color 0.3s;

  ${props => props.selected && `
    border-color: #4CAF50;
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
`;

export const SelectButton = styled.button`
  background-color: ${props => (props.disabled ? '#ccc' : '#4CAF50')};
  color: white;
  padding: 12px 30px;
  font-size: 18px;
  font-family: 'Marvin', serif;
  border: none;
  border-radius: 4px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: background-color 0.3s;

  &:hover {
    background-color: ${props => (props.disabled ? '#ccc' : '#45a049')};
  }
`;

export const InfoButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 35px;
  height: 35px;
  background-image: url(${infoButton});
  background-size: cover;
  background-position: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
