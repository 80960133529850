import { createGlobalStyle } from 'styled-components';

import MarvinRegular from '../assets/fonts/Marvin-Regular.otf';
import MarvinRound from '../assets/fonts/Marvin-Round.otf';
import MarvinShadow from '../assets/fonts/Marvin-Shadow.otf';

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Marvin';
    src: url(${MarvinRegular}) format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Marvin';
    src: url(${MarvinRound}) format('opentype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'MarvinShadow';
    src: url(${MarvinShadow}) format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    background-color: #f9f9f9;
    color: #333;
    font-family: 'Marvin', serif;
    line-height: 1.6;
  }

  h1, h2 {
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
  }
`;

export default GlobalStyles;
