import React, { useState, useEffect, useCallback } from 'react';
import useFetch from '../hooks/useFetch';
import GlobalStyles from '../styles/GlobalStyles';
import { Container } from '../styles/App.styles';
import Loader from './Loader';
import SubscriptionCheck from './SubscriptionCheck';
import CharacterSelection from './CharacterSelection';
import CharacterScene from './CharacterScene';
import LevelDisplay from './LevelDisplay';
import AchievementNotification from './AchievementNotification';
import SocialLinks from './SocialLinks';
import Referrals from './Referrals';
import calculateLevel from '../levelCalculator';
import characterImage from '../assets/characters/Character1.png';
import BalanceDisplay from './BalanceDisplay';
import DeviceCheck from './DeviceCheck';

const App = () => {
  const [username, setUsername] = useState(null);
  const [userId, setUserId] = useState(null);
  const [referrals, setReferrals] = useState(0);
  const [isSubscribed, setIsSubscribed] = useState(null);
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const [level, setLevel] = useState(0);
  const [copySuccess, setCopySuccess] = useState('');
  const [showAchievement, setShowAchievement] = useState(false);
  const [confirmedLevels, setConfirmedLevels] = useState([]);
  const [areImagesLoaded, setAreImagesLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [initialCheckDone, setInitialCheckDone] = useState(false);
  const [minimumLoadingTimeReached, setMinimumLoadingTimeReached] = useState(false);
  const [notificationQueue, setNotificationQueue] = useState([]);

  const { data: referralData, loading: loadingReferrals } = useFetch(`${process.env.REACT_APP_API_URL}/referrals/${username}`, {
    headers: { 'Cache-Control': 'no-cache' }
  });

  useEffect(() => {
    if (window.Telegram.WebApp.initData) {
      const initDataUnsafe = window.Telegram.WebApp.initDataUnsafe;
      const user = initDataUnsafe.user;
      if (user) {
        setUsername(user.username);
        setUserId(user.id);
      }
      window.Telegram.WebApp.expand();
      // localStorage.clear()
    }
  }, []);

  useEffect(() => {
    const savedConfirmedLevels = JSON.parse(localStorage.getItem('confirmedLevels')) || [];
    setConfirmedLevels(savedConfirmedLevels);
  }, []);

  useEffect(() => {
    if (referralData) {
      setReferrals(referralData.referrals);
      const currentLevel = calculateLevel(referralData.referrals);
      setLevel(currentLevel);
    }
  }, [referralData]);

  useEffect(() => {
    const checkSubscription = async () => {
      if (userId) {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/check_subscription/${userId}`, {
            headers: { 'Cache-Control': 'no-cache' }
          });
          const data = await response.json();
          setIsSubscribed(data.is_subscribed);
        } catch (error) {
          console.error('Error checking subscription:', error);
        }
      }
    };

    checkSubscription();
  }, [userId]);

  useEffect(() => {
    const checkCharacter = async () => {
      if (username) {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/get_character/${username}`, {
            headers: { 'Cache-Control': 'no-cache' }
          });
          const data = await response.json();
          setSelectedCharacter(data.character_id || null);
        } catch (error) {
          console.error('Error checking character:', error);
          setSelectedCharacter(null);
        }
      }
    };

    checkCharacter();
  }, [username]);

  const loadImages = async () => {
    const images = [characterImage]; 
    const promises = images.map((src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = resolve;
        img.onerror = reject;
      });
    });

    await Promise.all(promises);
    setAreImagesLoaded(true);
  };

  useEffect(() => {
    loadImages();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setMinimumLoadingTimeReached(true);
    }, 2500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!loadingReferrals && isSubscribed !== null && areImagesLoaded && minimumLoadingTimeReached) {
      setInitialCheckDone(true);
      setLoading(false);
    }
  }, [loadingReferrals, isSubscribed, areImagesLoaded, minimumLoadingTimeReached]);

  useEffect(() => {
    if (initialCheckDone) {
      const requiredLevels = Array.from({ length: level }, (_, i) => i + 1);
      const missingLevels = requiredLevels.filter(lvl => !confirmedLevels.includes(lvl));
      
      if (missingLevels.length > 0) {
        setNotificationQueue(missingLevels);
        setShowAchievement(true);
      }
    }
  }, [level, confirmedLevels, initialCheckDone]);

  const copyToClipboard = useCallback((text) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopySuccess('Copied!');
      setTimeout(() => setCopySuccess(''), 2000);
    }, () => {
      setCopySuccess('Failed to copy!');
    });
  }, []);

  const handleContinue = () => {
    window.location.reload();
  };

  const handleCharacterSelected = (characterId) => {
    setSelectedCharacter(characterId);
  };

  const handleAcceptAchievement = () => {
    if (notificationQueue.length > 0) {
      const nextLevel = notificationQueue.shift();
      const newConfirmedLevels = [...confirmedLevels, nextLevel];
      setConfirmedLevels(newConfirmedLevels);
      localStorage.setItem('confirmedLevels', JSON.stringify(newConfirmedLevels));
      setNotificationQueue(notificationQueue);
    } else {
      setShowAchievement(false);
    }
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (initialCheckDone && !isSubscribed) {
    return <SubscriptionCheck userId={userId} onContinue={handleContinue} />;
  }

  if (initialCheckDone && isSubscribed && !selectedCharacter) {
    return <CharacterSelection username={username} onCharacterSelected={handleCharacterSelected} />;
  }

  return (
    <DeviceCheck>
      <GlobalStyles />
      <Container>
        {showAchievement && notificationQueue.length > 0 && (
          <AchievementNotification 
            level={notificationQueue[0]} 
            onAccept={handleAcceptAchievement} 
          />
        )}
        <LevelDisplay level={level} />
        <CharacterScene characterId={selectedCharacter} confirmedLevels={confirmedLevels} />
        <BalanceDisplay level={level} />
        <Referrals 
          referrals={referrals} 
          copySuccess={copySuccess} 
          copyToClipboard={copyToClipboard} 
          username={username} 
        />
        <SocialLinks />
      </Container>
    </DeviceCheck>
  );
};

export default App;
