import React from 'react';
import { SceneContainer, CharacterImage, ShadowImage, LevelImage } from '../styles/CharacterScene.styles';
import levelImages from '../levelImages';
import backgroundImage from '../assets/scene_bg.png';
import shadowImage from '../assets/characters/shadow.png';
import characterConfig from '../characterConfig';

const CharacterScene = ({ characterId, confirmedLevels }) => {
  const character = characterConfig[characterId];

  return (
    <SceneContainer background={backgroundImage}>
      {levelImages.map((image, index) => (
        <LevelImage
          key={index}
          className="level-image"
          src={image.src}
          alt={`Level ${index + 1}`}
          style={{ display: confirmedLevels.includes(index + 1) ? 'block' : 'none' }}
          position={image.position}
          size={image.size} 
          zIndex={image.zIndex}
          transform={image.transform}
        />
      ))}
      {character && (
        <>
          <ShadowImage src={shadowImage} alt="Shadow" />
          <CharacterImage 
            src={character.src} 
            alt={character.name} 
            height={character.height}
            transform={character.transform}
          />
        </>
      )}
    </SceneContainer>
  );
};

export default CharacterScene;
