import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const SceneContainer = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  margin-top: 130px;
`;

export const CharacterImage = styled.img`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: ${props => props.transform || 'translateX(-50%)'};
  height: ${props => props.height || '100%'};
  width: auto;
  z-index: 4;
`;

export const ShadowImage = styled.img`
  position: fixed;
  bottom: 170px;
  left: 55%;
  transform: translateX(-50%);
  height: 150px;
  width: 50%;
  z-index: 2;
`;

export const LevelImage = styled.img`
  position: fixed;
  ${props => props.position};
  height: ${props => props.size || '90px'};
  z-index: ${props => props.zIndex || '20'};
  transform: ${props => props.transform || 'none'};
  animation: ${fadeIn} 0.5s ease-in-out;
`;
