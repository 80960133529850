import styled from 'styled-components';
import frameForLevel from '../assets/frameForReferrals.png';
import frameForLink from '../assets/frameForLink.png';
import infoButton from '../assets/infoButton.png';

export const ReferralsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 25px;
  z-index: 26;
  font-family: 'Marvin', sans-serif;
`;

export const ReferralsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 0 20px;
  position: relative;
  z-index: 21;
`;

export const ReferralsCount = styled.div`
  font-size: 32px;
  color: #FEE994;
  font-weight: bold;
  background-image: url(${frameForLevel});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0px 15px 5px 15px;
  z-index: 5;
  flex: 1;
  text-align: center;
`;

export const InviteButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 5px;
  z-index: 5;
  position: relative;

  img {
    z-index: 5;
    width: 230px;
    height: auto;
    margin-top: 5px;
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 3;
  background-image: url(${frameForLink});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 10px;
  flex-wrap: nowrap;
`;

export const CopyText = styled.div`
  display: flex;
  align-items: center;
  color: #804000;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    text-decoration: underline;
  }

  span {
    color: #CAA883;
  }
`;

export const CopyIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 5px;
  cursor: pointer;
`;

export const Message = styled.div`
  color: white;
  margin-top: 10px;
`;

export const InfoButton = styled.button`
  position: absolute;
  top: 2px;
  right: 2px;
  width: 25px;
  height: 25px;
  background-image: url(${infoButton});
  background-size: cover;
  background-position: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 10;
`;
