// styles/DeviceCheck.styles.js
import styled from 'styled-components';

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  text-align: center;
  padding: 20px;
  background-color: #000;
  color: #fff;
  font-family: 'Marvin', sans-serif;
`;

export const Heading = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;
`;

export const Text = styled.p`
  font-size: 1.25rem;
  margin-bottom: 20px;
`;

export const CloseButton = styled.button`
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Marvin', serif;
  transition: background-color 0.3s;

  &:hover {
    background-color: #45a049;
  }
`;
