const levels = [1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024, 2048, 4096, 8192, 16384];

const calculateLevel = (referrals) => {
  let level = 0;

  for (let i = 0; i < levels.length; i++) {
    if (referrals < levels[i]) {
      break;
    }
    level = i + 1;
  }

  return level;
};

export default calculateLevel;
