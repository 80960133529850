import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { PopupContainer, PopupContent, CloseButton, LevelDetail, Text, LevelInfo } from '../styles/ReferralInfo.styles';

const levels = [
  { level: 1, friends: 1, coga: 150 },
  { level: 2, friends: 2, coga: 300 },
  { level: 3, friends: 4, coga: 450 },
  { level: 4, friends: 8, coga: 600 },
  { level: 5, friends: 16, coga: 750 },
  { level: 6, friends: 32, coga: 1050 },
  { level: 7, friends: 64, coga: 8700 },
  { level: 8, friends: 128, coga: 12000 },
  { level: 9, friends: 256, coga: 13500 },
  { level: 10, friends: 512, coga: 15000 },
  { level: 11, friends: 1024, coga: 16500 },
  { level: 12, friends: 2048, coga: 18000 },
  { level: 13, friends: 4096, coga: 19500 },
  { level: 14, friends: 8192, coga: 21000 },
  { level: 15, friends: 16384, coga: 22500 },
];

const ReferralInfo = ({ onClose }) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (event.target.id === 'popup-container') {
          onClose();
        }
      };
  
      window.addEventListener('click', handleClickOutside);
      return () => {
        window.removeEventListener('click', handleClickOutside);
      };
    }, [onClose]);
  
    return ReactDOM.createPortal(
      <PopupContainer id="popup-container">
        <PopupContent>
          <Text>You have a great opportunity to get <div className='gold'>150,000 $COGA TOKENS</div> before the main POOMB GAME starts!</Text>
          <Text>Invite friends using your referral link so you wouldn't miss the chance to <div className='gold'>EARN REAL MONEY!</div></Text>
          <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
            {levels.map((lvl, index) => (
              <LevelDetail key={index} active={index < levels.length}>
                <LevelInfo>LVL {lvl.level} - {lvl.friends} FRIEND{lvl.friends > 1 ? 'S' : ''}</LevelInfo>
                <LevelInfo>You get - {lvl.coga} $COGA</LevelInfo>
              </LevelDetail>
            ))}
          </div>
          <CloseButton onClick={onClose}>Close</CloseButton>
        </PopupContent>
      </PopupContainer>,
        document.getElementById('portal-root')
    );
  };
  
  export default ReferralInfo;