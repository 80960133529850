import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const NotificationContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  padding: 20px;
  z-index: 1000;
  width: 80%;
  max-width: 400px;
  color: white;
  text-align: center;
  transition: opacity 2s ease-in-out;

  &.transparent {
    opacity: 0;
  }
`;

export const NotificationContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AcceptButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

export const RewardImage = styled.img`
  margin-top: 20px;
  width: 100px;
  height: 100px;
  transition: transform 2s ease, opacity 2s ease;

  &.move {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: moveToScene 2s forwards, fadeIn 2s forwards;
  }

  @keyframes moveToScene {
    0% {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    100% {
      top: 0%;
      left: 50%;
      transform: translate(-50%, 0%);
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
