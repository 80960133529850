import styled from 'styled-components';
import frameForLevel from '../assets/frameForLevel.png';
import infoButton from '../assets/infoButton.png';

export const LevelContainer = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  align-items: flex-end;
  font-family: 'Marvin', serif;
`;

export const LevelText = styled.div`
  font-size: 32px;
  color: #F8CE00;
  font-family: 'Marvin', serif;
  font-weight: bold;
  background-image: url(${frameForLevel});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  z-index: 6;
  padding: 0 10px 3px 10px;
`;

export const LabelText = styled.div`
  font-size: 24px;
  color: #F8CE00;
  font-family: 'Marvin', serif;
  font-weight: bold;
  margin-left: 10px;
  text-transform: uppercase;
  z-index: 6;
  text-shadow: 
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
`;

export const InfoButton = styled.button`
  position: absolute;
  top: 2px;
  right: 76px;
  width: 17px;
  height: 17px;
  background-image: url(${infoButton});
  background-size: cover;
  background-position: center;
  background-color: transparent;
  z-index: 6;
  border: none;
  cursor: pointer;
`;
