import React from 'react';
import { BalanceContainer, BalanceText, CoinImage } from '../styles/BalanceDisplay.styles';
import coinImage from '../assets/coin.png';

const cumulativeValues = [150, 450, 900, 1500, 2250, 3300, 12000, 24000, 37500, 52500, 69000, 87000, 106500, 127500, 150000];

const BalanceDisplay = ({ level }) => {
  const balance = cumulativeValues[level - 1] || 0;

  return (
    <BalanceContainer>
      <CoinImage src={coinImage} alt="Coin" />
      <BalanceText>{balance}</BalanceText>
    </BalanceContainer>
  );
};

export default BalanceDisplay;
