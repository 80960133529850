import styled from 'styled-components';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export const LevelInfoContainer = styled.div`
  width: 70%;
  max-height: 60%;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  padding: 20px;
  z-index: 1001;
  color: white;
`;

export const LevelRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: ${({ active }) => (active ? '#4CAF50' : 'rgba(255, 255, 255, 0.2)')};
  border-radius: 5px;
  margin-bottom: 10px;
`;

export const LevelText = styled.div`
  font-size: 18px;
  font-family: 'Marvin', sans-serif;
`;

export const RewardImage = styled.img`
  width: 50px;
  height: 50px;
`;

export const CloseButton = styled.button`
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  font-family: 'Marvin', serif;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  display: block;
  width: 100%;
  text-align: center;
`;
