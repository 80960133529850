const characterConfig = {
    1: {
      src: require('./assets/characters/Character1.png'),
      height: '100%',
      transform: 'translateX(-50%)',
    },
    2: {
      src: require('./assets/characters/Character2.png'),
      height: '112%',
      transform: 'translateX(-50%)',
    },
    3: {
      src: require('./assets/characters/Character3.png'),
      height: '133%',
      transform: 'translate(-50%, 4%)',
    },
    4: {
      src: require('./assets/characters/Character4.png'),
      height: '109%',
      transform: 'translate(-41%, 2%)',
    },
    5: {
      src: require('./assets/characters/Character5.png'),
      height: '117%',
      transform: 'translateX(-55%)',
    },
  };
  
  export default characterConfig;
  