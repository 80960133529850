import character1 from './assets/characters/Character1.png';
import character2 from './assets/characters/Character2.png';
import character3 from './assets/characters/Character3.png';
import character4 from './assets/characters/Character4.png';
import character5 from './assets/characters/Character5.png';

const characters = [
  { id: 1, name: 'Capybara', src: character1 },
  { id: 2, name: 'Tarsius', src: character2 },
  { id: 3, name: 'Peacock', src: character3 },
  { id: 4, name: 'Tiger', src: character4 },
  { id: 5, name: 'Blueme', src: character5 }
];

export default characters;
