const levelImages = [
  { src: require('./assets/levels/level1.png'), position: 'bottom: 36%; right: 0px;', size: '90px', zIndex: 3, transform: 'none' },
  { src: require('./assets/levels/level2.png'), position: 'top: 315px; left: 50%;', size: '190px', zIndex: 20, transform: 'translate(-50%, -50%)' },
  { src: require('./assets/levels/level3.png'), position: 'bottom: 11%; left: 2%;', size: '50px', zIndex: 27, transform: 'none' },
  { src: require('./assets/levels/level4.png'), position: 'bottom: 18%; left: 0px;', size: '250px', zIndex: 4, transform: 'none' },
  { src: require('./assets/levels/level5.png'), position: 'top: 5%; left: 0px;', size: '23%', zIndex: 4, transform: 'none' },
  { src: require('./assets/levels/level6.png'), position: 'top: 90px; left: 0px;', size: '250px', zIndex: 4, transform: 'none' },
  { src: require('./assets/levels/level7.png'), position: 'top: 230px; left: 0;', size: '75px', zIndex: 4, transform: 'none' },
  { src: require('./assets/levels/level8.png'), position: 'bottom: 38%; left: 5%;', size: '100px', zIndex: 20, transform: 'none' },
  { src: require('./assets/levels/level9.png'), position: 'top: 13%; right: 0;', size: '250px', zIndex: 4, transform: 'none' },
  { src: require('./assets/levels/level10.png'), position: 'top: -7%; right: 10px;', size: '250px', zIndex: 3, transform: 'none' },
  { src: require('./assets/levels/level11.png'), position: 'top: -20px; right: 20%;', size: '55px', zIndex: 20, transform: 'none' },
  { src: require('./assets/levels/level12.png'), position: 'top: -11%; right: 17%;', size: '130px', zIndex: 20, transform: 'none' },
  { src: require('./assets/levels/level13.png'), position: 'bottom: 30%; right: 0;', size: '50px', zIndex: 20, transform: 'none' },
  { src: require('./assets/levels/level14.png'), position: 'bottom: 35%; right: 0;', size: '80px', zIndex: 20, transform: 'none' },
  { src: require('./assets/levels/level15.png'), position: 'top: 177px; left: 50%;', size: '230px', zIndex: 20, transform: 'translate(-50%, -50%)' },
];

export default levelImages;
