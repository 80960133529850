// components/DeviceCheck.js
import React, { useEffect, useState } from 'react';
import { MessageContainer, Heading, Text } from '../styles/DeviceCheck.styles';

const DeviceCheck = ({ children }) => {
  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android|iPad|iPhone|iPod|Windows Phone/i.test(userAgent)) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  if (!isMobile) {
    return (
      <MessageContainer>
        <Heading>Access Restricted</Heading>
        <Text>This application is only accessible from mobile devices.</Text>
      </MessageContainer>
    );
  }

  return <>{children}</>;
};

export default DeviceCheck;
