import styled from 'styled-components';

export const BalanceContainer = styled.div`
  position: fixed;
  top: 9%;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  border-radius: 10px;
  z-index: 25;
  display: flex;
  align-items: center;
  color: #F8CE00;
  font-family: 'Marvin', sans-serif;
`;

export const BalanceText = styled.div`
  font-size: 36px;
  font-weight: bold;
  text-shadow: 
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
   margin-left: 10px;
`;

export const CoinImage = styled.img`
  width: 60px;
  height: 60px;
`;
