import styled from 'styled-components';
import mainBG from '../assets/mainBG.png'; 

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-image: url(${mainBG});
  background-size: cover;
  background-position: center;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  z-index: 3;
`;

export const LevelText = styled.h1`
  margin-left: 10px;
  font-size: 24px;
  color: white;
`;

export const Message = styled.div`
  color: white;
  margin-top: 10px;
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  z-index: 3;
`;

export const SendButtonContainer = styled.div`
  margin-top: 10px;
`;
