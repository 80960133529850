import React, { useState } from 'react';
import { NotificationContainer, NotificationContent, AcceptButton, RewardImage } from '../styles/AchievementNotification.styles';
import levelImages from '../levelImages';

const cogatokens = [150, 300, 450, 600, 750, 900, 1050, 1200, 1350, 1500, 1650, 1800, 1950, 2100, 2250];
const countinvite = [1, 3, 4, 8, 16, 32, 64, 128, 256, 512, 1024, 2048, 4096, 8192];

const AchievementNotification = ({ level, onAccept, userId }) => {
  const [isMoving, setIsMoving] = useState(false);
  const rewardImage = levelImages[level - 1]?.src;
  const tokens = cogatokens[level - 1];
  const invites = countinvite[level - 1];

  const handleConfirm = async () => {
    setIsMoving(true);
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/notify`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          chat_id: userId,
          text: `Congratulations!! You have unlocked your Poomby's Hub Level ${level}! \nGet your POOMBY ITEM and ${tokens} $COGA TOKENS. \n\nInvite ${invites} more friend${invites > 1 ? 's' : ''} to open the next ITEM and collect more $COGA.`,
        }),
      });
    } catch (error) {
      console.error('Error sending notification:', error);
    }
    setTimeout(() => {
      onAccept();
      setIsMoving(false);
    }, 1000);
  };

  return (
    <NotificationContainer className={isMoving ? 'transparent' : ''}>
      <NotificationContent>
        <h2>Congratulations!!</h2>
        <p>
          You have unlocked your Poomby's Hub Level {level}! <br />
          Get your POOMBY ITEM and {tokens} $COGA TOKENS <br />
        </p>
        <p>Invite {invites} more friend{invites > 1 ? 's' : ''} to open next ITEM and collect more $COGA</p>
        {rewardImage && <RewardImage src={rewardImage} alt={`Reward for level ${level}`} className={isMoving ? 'move' : ''} />}
        <AcceptButton onClick={handleConfirm}>Confirm</AcceptButton>
      </NotificationContent>
    </NotificationContainer>
  );
};

export default AchievementNotification;
