import styled from 'styled-components';

export const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
`;
export const PopupContent = styled.div`
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 500px;
  width: 80%;
  font-family: 'Marvin', serif;
  color: white;
  z-index: 10001;
`;

export const Text = styled.p`
  font-size: 16px;
  margin-bottom: 20px;
  z-index: 50;
  
  .gold{
    color: #F8CE00;
  }
`;

export const LevelDetail = styled.div`
  background-color: #F8CE00;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  font-family: 'Marvin', serif;
  color: black;
  z-index: 50;
`;

export const LevelInfo = styled.p`
  font-size: 16px;
  margin: 5px 0;
  z-index: 50;
`;

export const CloseButton = styled.button`
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  font-family: 'Marvin', serif;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  font-size: 16px;
  display: block;
  width: 100%;
  text-align: center;
  z-index: 50;
`;
