import React from 'react';
import { Overlay, LevelInfoContainer, LevelRow, LevelText, RewardImage, CloseButton } from '../styles/LevelInfo.styles';
import levelImages from '../levelImages';

const levels = [1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024, 2048, 4096, 8192, 16384];

const LevelInfo = ({ level, onClose }) => {
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <Overlay onClick={handleOverlayClick}>
      <LevelInfoContainer>
        {levels.map((lvl, index) => (
          <LevelRow key={lvl} active={index < level}>
            <LevelText>Level {index + 1}</LevelText>
            <RewardImage src={levelImages[index]?.src} alt={`Reward for level ${index + 1}`} />
          </LevelRow>
        ))}
        <CloseButton onClick={onClose}>Close</CloseButton>
      </LevelInfoContainer>
    </Overlay>
  );
};

export default LevelInfo;
