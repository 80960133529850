import React, { useRef, useEffect } from 'react';
import { InformationContainer, InformationContent, CloseButton } from '../styles/InformationSection.styles';

const InformationSection = ({ text, buttonText, onClose }) => {
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupRef, onClose]);

  return (
    <InformationContainer>
      <InformationContent ref={popupRef}>
        <p>{text}</p>
        <CloseButton onClick={onClose}>{buttonText}</CloseButton>
      </InformationContent>
    </InformationContainer>
  );
};

export default InformationSection;
